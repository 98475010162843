(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

document.oncontextmenu = new Function("return false"); // блокируем контекстное меню

$(window).keypress(function (event) {
    blockKey(event);
}); // вызов функции блокировки клавиш
$(document).keypress(function (event) {
    blockKey(event);
}); // вызов функции блокировки клавиш

function blockKey(event) {
    keyCode = event.keyCode;
    if (keyCode == '155') {
        // не работает, т.к. PrintScreen является функцией заложенной в ОС, а не в браузер - до неё мы достучаться не сможем
        alert('printscreen');
    }
    if (keyCode == '17') {
        // блокировка Ctrl
        alert('Блокировка включена.');
    }
}

$(document).ready(function () {
    // modal
    $('#genus__trigger').click(function () {
        $('.modal__genus').addClass('modal__visible');
    });
    $('#eic__trigger').click(function () {
        $('.modal__eic').addClass('modal__visible');
    });
    $('#hnpk__trigger').click(function () {
        $('.modal__hnpk').addClass('modal__visible');
    });
    $('#ed00__trigger').click(function () {
        $('.modal__ed00').addClass('modal__visible');
    });

    $(document).click(function (e) {
        if ($(e.target).hasClass('modal__visible')) {
            $('.modal').removeClass('modal__visible');
        }
    });

    //menu toggle
    function burgerChange() {
        if ($('.menu_nav').css('display') == 'block') {
            $('.burger_top').css({ 'transform': 'none' });
            $('.burger_bottom').css({ 'transform': 'none' });
            $('.burger_meat').css({ 'opacity': '1' });
        } else {
            $('.burger_top').css({ 'transform': 'translateY(12px) rotate(45deg)' });
            $('.burger_bottom').css({ 'transform': 'translateY(-8px) rotate(-45deg)' });
            $('.burger_meat').css({ 'opacity': '0' });
        }
    }
    $('.nav-toggle').click(function () {
        burgerChange();
        $(".menu_nav").slideToggle('slow');
    });

    // slider
    // $('.slider').slick({
    //     // autoplay: true,
    //     dots: true,
    //     infinite: true,
    //     fade: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: true,
    //     prevArrow: '<div class="slick_prev"></div>',
    //     nextArrow: '<div class="slick_next"></div>'
    //   });
    // $('.slider__nav').slick({
    // infinite: true,
    // slidesToShow: 4,
    // slidesToScroll: 1,
    // asNavFor: '.slider',
    // arrows: true,
    // prevArrow: '<div class="slick_prev slick__nav_prev"></div>',
    // nextArrow: '<div class="slick_next slick__nav_next"></div>'
    // });
});

var expandGalleryItem = function expandGalleryItem() {
    var overlay = document.querySelector('.overlay');
    var slider = document.querySelector('.slider');
    var galleryItems = document.querySelector('.slider__nav');

    if (!overlay || !slider || !galleryItems) {
        return;
    }

    galleryItems.addEventListener('click', function (e) {
        if (e.target.classList.contains('slide__nav')) {
            e.preventDefault();
            var clone = e.target.cloneNode(true);
            clone.classList.add('expanded');
            overlay.classList.remove('is-hidden');
            slider.classList.remove('is-hidden');
            slider.insertAdjacentElement('afterbegin', clone);
        }
    });
};

var closeGalleryItem = function closeGalleryItem() {
    var overlay = document.querySelector('.overlay');
    var slider = document.querySelector('.slider');

    if (!overlay || !slider) {
        return;
    }

    overlay.addEventListener('click', function () {
        slider.innerHTML = '';
        overlay.classList.add('is-hidden');
        slider.classList.add('is-hidden');
    });
};

window.addEventListener('load', function () {
    expandGalleryItem();
    closeGalleryItem();
});

},{}]},{},[1]);
